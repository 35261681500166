import styled, { css } from "styled-components";

import { device } from "../../../utils/breakpoints";
import { PageThemeType } from "../../../utils/types";
import { fadeIn, fadeOut } from "../../../utils/animations";

interface PageProps {
  show: boolean;
}

export const ArrowWrapper = styled.div`
  width: 100%;
  height: 12.5vw;
  animation: ${(props: PageProps) =>
    props.show
      ? css`
          ${fadeIn} 300ms linear
        `
      : css`
          ${fadeOut} 300ms linear
        `};
  animation-fill-mode: forwards;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  svg {
    margin-left: 1vw;
  }

  &:last-of-type {
    justify-content: flex-end;

    svg {
      margin-left: 0;
      margin-right: 1vw;
    }
  }
`;

export const SinglePageWrapper = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  min-height: 100%;

  @media ${device.tablet}, ${device.tabletLandscape}, ${device.computer} {
    min-height: 100vh;
  } ;
`;

export const ScrollButtonsWrapper = styled.div`
  position: fixed;
  width: 54px;
  top: 54%;
  right: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transform: translateY(-50%);

  @media ${device.tablet} {
    background: white;
  }

  @media ${device.tablet}, ${device.tabletLandscape} {
    right: 60px;
    border-radius: 8px;
    padding: 10px 0;
  }

  @media ${device.computer} {
    top: 60%;
    right: 120px;
  }

  @media ${device.desktop} {
    right: 120px;
  } ;
`;

interface PageIndicatorProps {
  selected?: number;
  pageTheme: PageThemeType;
}

export const PageIndicator = styled.button`
  background: ${(props: PageIndicatorProps) =>
    props.pageTheme === "white"
      ? props.selected
        ? "#0500FF"
        : "#151515"
      : props.pageTheme === "black"
      ? props.selected
        ? "#0500FF"
        : "#FFFFFF"
      : props.selected
      ? "#0500FF"
      : "#151515"};
  border-width: ${(props: PageIndicatorProps) => (props.selected ? "4px" : 0)};
  border-style: solid;
  border-color: ${(props: PageIndicatorProps) =>
    props.pageTheme === "white"
      ? props.selected
        ? "#0500FF"
        : "#151515"
      : props.pageTheme === "black"
      ? props.selected
        ? "#0500FF"
        : "#FFFFFF"
      : props.selected
      ? "#FFFFFF"
      : "#151515"};
  outline: none;
  box-sizing: content-box;
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    opacity: 0.24;
    transition: 0.3s;
  }

  &:active {
    opacity: 0.32;
  }

  @media ${device.tablet} {
    border-color: ${(props: PageIndicatorProps) =>
      props.selected ? "#0500FF" : "#151515"};
    background: ${(props: PageIndicatorProps) =>
      props.selected ? "#0500FF" : "#151515"};
  }

  @media ${device.computer} {
    border-color: ${(props: PageIndicatorProps) =>
      props.pageTheme === "white"
        ? props.selected
          ? "#0500FF"
          : "#151515"
        : props.pageTheme === "black"
        ? props.selected
          ? "#0500FF"
          : "#FFFFFF"
        : props.selected
        ? "#FFFFFF"
        : "#151515"};
    background: ${(props: PageIndicatorProps) =>
      props.pageTheme === "white"
        ? props.selected
          ? "#0500FF"
          : "#151515"
        : props.pageTheme === "black"
        ? props.selected
          ? "#0500FF"
          : "#FFFFFF"
        : props.selected
        ? "#0500FF"
        : "#151515"};
  }

  @media ${device.tablet}, ${device.tabletLandscape}, ${device.computer} {
    margin: 16px auto;
    width: 8px;
    height: 8px;
  }

  @media ${device.computer}, ${device.desktop} {
    width: 14px;
    height: 14px;
  }

  @media ${device.laptopL} {
    margin: 22px auto;
  } ;
`;

import { replaceSpacesInString } from "droptheword";

import { getLanguage } from "./i18n";

export const fixConjunctions = (text: string) => {
  const currentLanguage = getLanguage();
  const dropWordList = currentLanguage === "en"
    ? ["for", "since", "because", "before", "once", "than", "that", "nor", "but", "or", "yet", "so", "is", "of", "and", "the", "The", "in"]
    : currentLanguage === "pl"
      ? ["W", "nie", "w", "z", "o", "i", "a", "na", "do", "dla", "oraz", "za", "lub", "albo", "bo", "ale", "jego", "co", "już", "przez", "nam", "go", "by", "m.in.", "po", "który", "która", "które", "którego", "której", "któremu"]
      : ["z"];
  return replaceSpacesInString({ text, dropWordList });
};
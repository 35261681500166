import styled, { css } from "styled-components";

import { PageChange } from "../";
import { device } from "../../../../utils/breakpoints";
import { fadeIn, fadeOut } from "../../../../utils/animations";
import ArrowUp from "-!svg-react-loader!../../../../assets/images/arrowUp.svg";

interface FooterProps {
  animation?: PageChange;
}

export const Footer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 2vh 6vw 0 6vw;
  display: grid;
  grid-template-columns: auto 50px;
  z-index: 100;
  height: 68px;
  animation: ${(props: FooterProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;

  @media ${device.tablet}, ${device.tabletLandscape} {
    padding: 2vh 61px 0 61px;
  }

  @media ${device.computer} {
    padding: 0 120px 0 64px;
  }

  @media ${device.desktop} {
    padding: 0 120px 0 120px;
  } ;
`;

export const BackToTopWrapper = styled.button`
  grid-column: 2;
  display: flex;
  align-items: flex-end;
  border: none;
  outline: none;
  background: none;
`;

export const Img = styled(ArrowUp)`
  width: 50px;
  transition: 0.3s;

  &:hover {
    opacity: 0.24;
  }
`;

import React from "react";

import { PageChange } from "../";
import { Footer, BackToTopWrapper, Img } from "./style";
import { MouseEventType } from "../../../../utils/types";
// import ArrowUp from "-!svg-react-loader!../../../../assets/images/arrowUp.svg";

interface ScrollToTopProps {
  scrollToFirstPage: (e: MouseEventType) => void;
  pageChange?: PageChange;
};

const ScrollToTop: React.FC<ScrollToTopProps> = ({ 
  scrollToFirstPage,
  pageChange
}) => {
  const changeURL = (e: MouseEventType) => {
    e.preventDefault();
    scrollToFirstPage(e);
  };

  return (
    <Footer animation={pageChange}>
      <BackToTopWrapper onClick={changeURL}>
        <Img />
      </BackToTopWrapper>
    </Footer>
  );
};

export default ScrollToTop;
import React, { useState } from "react";

import { LoaderWrapper, StyledLoader } from "./style";

export interface LoaderProps {
  fullPage?: boolean;
  height?: number | string;
  width?: number | string;
  color?: string;
};

export const Loader: React.FC<LoaderProps> = ({ fullPage, ...props }) => (
  <LoaderWrapper fullPage={fullPage}>
    <StyledLoader {...props} />
  </LoaderWrapper>
);

export default Loader;